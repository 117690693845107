<template>
  <div class="report-form">
    <div class="report-form__left-column">
      <div class="report-form__row">
        <div
          v-for="(field, index) in primaryFilter"
          :key="index"
          class="report-form__row-column"
        >
          <component
            v-model="field.value"
            v-bind="{
              ...fieldTypes[field.editor].attributes,
            }"
            :is="fieldTypes[field.editor].component"
            :label="field.displayName"
            :options="field.list"
            @select="onSelect(field)"
          />
        </div>
      </div>
      <div
        v-show="!isHiddenSecondaryFilter"
        class="report-form__row report-form__secondary-filter"
      >
        <div
          v-for="(field, index) in secondaryFilter"
          :key="index"
          class="report-form__row-column"
        >
          <component
            v-model="field.value"
            v-bind="{
              ...fieldTypes[field.editor].attributes,
            }"
            :is="fieldTypes[field.editor].component"
            :label="field.displayName"
            :options="field.list"
            @select="onSelect(field)"
          />
        </div>
      </div>

      <div class="report-form__buttons-group">
        <sm-button
          :disabled="loadingSatusReport"
          :isLoading="loadingSatusReport"
          @click="onCreate"
          class="report__button"
          >Сформировать</sm-button
        >
        <sm-button
          v-if="report"
          :disabled="loadingSatusReport"
          @click="onOpenExportReportModal"
          class="report__button"
          >Сохранить</sm-button
        >
        <sm-button
          v-if="!report"
          :disabled="loadingSatusReport"
          @click="onOpenCreateAndDownloadReportModal"
          class="report__button"
          >Сформировать и экспортировать</sm-button
        >
      </div>
    </div>

    <div v-show="secondaryFilter.length" class="report-form__right-column">
      <button
        :class="{
          'report-form__button-toggle--open': !isHiddenSecondaryFilter,
        }"
        class="report-form__button-toggle"
        :isLoading="isLoadingReport"
        @click="isHiddenSecondaryFilter = !isHiddenSecondaryFilter"
      >
        <sm-icon
          name="chevron-down"
          outline
          stroke-width="1.5"
          class="report-form__button-toggle-icon"
        />
      </button>
    </div>
    <sm-modal
      :show="showCreateAndDownloadReportModal"
      :modalTitle="createAndDownloadReportModalTitle"
      class="report-modal"
      @close="onCloseCreateAndDownloadReportModal"
    >
      <template #body>
        <sm-select
          class="report-modal__field"
          v-model="currentExportType"
          :options="formatTypesList"
        />
        <div class="report-modal__buttons">
          <sm-button
            class="report-modal__button report-modal__button--left"
            :is-loading="isLoadingGenerateAndExportReport"
            :disabled="!currentExportType"
            @click="onGenerateAndExportReport"
            >Сформировать и сохранить</sm-button
          >
          <sm-button
            class="report-modal__button"
            outline
            neutrall
            @click="onCloseCreateAndDownloadReportModal"
            >Отмена
          </sm-button>
        </div>
      </template>
    </sm-modal>
    <sm-modal
      :show="showExportReportModal"
      :modalTitle="exportReportModalTitle"
      class="report-modal"
      @close="onCloseExportReportModal"
    >
      <template #body>
        <sm-select
          class="report-modal__field"
          v-model="currentExportType"
          :options="formatTypesList"
        />
        <div class="report-modal__buttons">
          <sm-button
            class="report-modal__button report-modal__button--left"
            :is-loading="isLoadingGenerateAndExportReport"
            :disabled="!currentExportType"
            @click="onGenerateAndExportReport"
            >Переформировать и сохранить</sm-button
          >
          <sm-button
            class="report-modal__button"
            :is-loading="isLoadingExportReport"
            :disabled="!currentExportType"
            @click="onExportExistingReport"
            >Сохранить сформированный отчет
          </sm-button>
        </div>
      </template>
    </sm-modal>
  </div>
</template>

<script>
import SmInput from '@/components/common/forms/SmInput.vue';
import SmCheckbox from '@/components/common/forms/SmCheckbox.vue';
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmDatepicker from '@/components/common/forms/SmDatepicker.vue';
import smIcon from '@/components/common/SmIcon.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmModal from '@/components/common/modals/SmModal.vue';

export default {
  name: 'SmReportFilter',

  components: {
    SmDatepicker,
    SmInput,
    SmCheckbox,
    SmSelect,
    smIcon,
    SmButton,
    SmModal,
  },

  model: {
    prop: 'form',
  },

  props: {
    form: {
      type: Array,
      required: true,
    },

    isLoadingReport: {
      type: Boolean,
      default: false,
    },

    report: {
      type: Boolean,
      required: true,
    },

    loadingSatusReport: {
      type: Boolean,
      default: false,
    },

    isLoadingGenerateAndExportReport: {
      type: Boolean,
      default: false,
    },

    isLoadingExportReport: {
      type: Boolean,
      default: false,
    },

    showExportReportModal: {
      type: Boolean,
      default: false,
    },

    showCreateAndDownloadReportModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fieldTypes: {
        String: {
          component: 'sm-input',
          attributes: {
            type: 'text',
          },
        },
        Int: {
          component: 'sm-input',
          attributes: {
            type: 'number',
            step: 1,
          },
        },
        Decimal: {
          component: 'sm-input',
          attributes: {
            type: 'number',
            step: 0.1,
          },
        },
        Bool: {
          component: 'sm-checkbox',
          attributes: {
            class: 'report-form__checkbox',
          },
        },
        List: {
          component: 'sm-select',
          attributes: {
            search: true,
          },
        },
        Date: {
          component: 'sm-datepicker',
          attributes: {
            type: 'date',
            format: 'DD.MM.YYYY',
            valueType: 'DD.MM.YYYY',
            timeTitleFormat: 'DD.MM.YYYY',
          },
        },
        DateTime: {
          component: 'sm-datepicker',
          attributes: {
            type: 'date',
            format: 'DD.MM.YYYY',
            valueType: 'DD.MM.YYYY HH:mm:ss',
            timeTitleFormat: 'DD.MM.YYYY',
          },
        },
        PeriodDate: {
          component: 'sm-datepicker',
          attributes: {
            type: 'date',
            format: 'DD.MM.YYYY',
            valueType: 'DD.MM.YYYY',
            timeTitleFormat: 'DD.MM.YYYY',
            'show-shortcuts': true,
            range: true,
          },
        },
        PeriodDateTime: {
          component: 'sm-datepicker',
          attributes: {
            type: 'datetime',
            format: 'DD.MM.YYYY HH:mm',
            valueType: 'DD.MM.YYYY HH:mm:ss',
            timeTitleFormat: 'DD.MM.YYYY HH:mm',
            'show-shortcuts': true,
            range: true,
          },
        },
      },
      isHiddenSecondaryFilter: true,
      currentExportType: 'xlsx',
      createAndDownloadReportModalTitle: 'Выберите формат экспорта',
      exportReportModalTitle: 'Выберите формат экспорта',
      formatTypesList: [
        {
          name: 'xlsx',
          value: 'xlsx',
        },
        {
          name: 'csv',
          value: 'csv',
        },
      ],
    };
  },

  computed: {
    primaryFilter() {
      return this.form.filter((field) => field.isPrimary);
    },

    secondaryFilter() {
      return this.form.filter((field) => !field.isPrimary);
    },
  },

  methods: {
    onSelect(value) {
      this.$emit('select', value);
    },

    onCreate() {
      this.$emit('create');
    },

    onOpenExportReportModal() {
      this.$emit('openExportReportModal');
    },

    onOpenCreateAndDownloadReportModal() {
      this.$emit('openCreateAndDownloadReportModal');
    },

    onCloseCreateAndDownloadReportModal() {
      this.$emit('closeCreateAndDownloadReportModal');
      this.currentExportType = 'xlsx';
    },

    onCloseExportReportModal() {
      this.$emit('closeExportReportModal');
      this.currentExportType = 'xlsx';
    },

    onGenerateAndExportReport() {
      this.$emit('generateAndExportReport', this.currentExportType);
    },

    onExportExistingReport() {
      this.$emit('exportExistingReport', this.currentExportType);
    },
  },
};
</script>

<style lang="scss">
.report-form {
  display: flex;
  margin-bottom: 20px;

  border: 1px solid var(--gray);
  border-radius: 10px;

  transition: opacity 10s;
}

.report-form__left-column {
  padding: 20px;
  width: 100%;
}

.report-form__left-column + .report-form__right-column {
  border-left: 1px solid var(--gray);
}

.report-form__right-column {
  width: 66px;
}

.report-form__button-toggle {
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;

  border: none;
  background: none;

  transition: transform 0.5s;
}

.report-form__button-toggle--open {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.report-form__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  margin-bottom: 15px;
}

.report-form__row-column {
  flex: 0 0 25%;
  align-self: center;
  margin-bottom: 15px;
  padding: 0 15px;
  max-width: 25%;

  @media only screen and (max-width: 1279px) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @media only screen and (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.report-form__buttons-group {
  display: flex;
  justify-content: flex-end;
  width: 600px;
  max-width: 100%;
  margin-left: auto;
}

.report-form__button {
  flex: 0 0 calc(25% - 15px);

  @media only screen and (max-width: 1279px) {
    flex: 0 0 calc(100% / 3 - 15px);
  }

  @media only screen and (max-width: 767px) {
    flex: 0 0 calc(50% - 15px);
  }

  @media only screen and (max-width: 575px) {
    flex: 0 0 100%;
  }
}

.report__button {
  // flex-grow: 0;
  // flex-shrink: 0;
  // width: 200px;
  margin: 0 7px;
}

.report-form__button-toggle-icon {
  width: 50px;
  height: 50px;

  color: var(--gray);
}

.report-modal .modal__content {
  width: 500px;
}

.report-modal__buttons {
  display: flex;
  gap: 20px;
  width: 500px;
  max-width: 500px;

  @media (max-width: 550px) {
    width: auto;
  }
}

// .report-modal__button--left {
//   margin-right: 20px;
// }

.report-modal__field {
  margin-bottom: 30px;
}
</style>
