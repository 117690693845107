<template>
  <sm-page-loader v-if="isLoadingPage" />

  <sm-permission-error-message v-else-if="!hasPermission" />

  <div v-else class="report">
    <sm-breadcrumbs class="report__breadcrumbs" :items="breadcrumbs" />

    <sm-report-filter
      v-model="form"
      :isLoadingReport="isLoadingReport"
      :report="Boolean(report)"
      :isLoadingGenerateAndExportReport="isLoadingGenerateAndExportReport"
      :isLoadingExportReport="isLoadingExportReport"
      :showExportReportModal="showExportReportModal"
      :showCreateAndDownloadReportModal="showCreateAndDownloadReportModal"
      :loadingSatusReport="loadingSatusReport"
      @select="onSelect"
      @create="onCreate"
      @generateAndExportReport="onGenerateAndExport"
      @exportExistingReport="onExportExisting"
      @openCreateAndDownloadReportModal="onOpenCreateAndDownloadReportModal"
      @closeCreateAndDownloadReportModal="onCloseCreateAndDownloadReportModal"
      @openExportReportModal="onOpenExportReportModal"
      @closeExportReportModal="onCloseExportReportModal"
    />

    <div v-if="report" class="report__table-wrapper">
      <!-- <div class="report__link-group">
        <router-link
          v-for="link in reportLink"
          :key="link.text"
          to=""
          @click.native="onDownload(link.type)"
          class="report__link"
          >{{ link.text }}</router-link
        >
      </div> -->
      <div class="report__table">
        <div v-html="report" @click.prevent="onClickHyperlink"></div>
      </div>
    </div>

    <div
      v-if="showNotificationMessage"
      class="notifications__container report-notifications__container"
    >
      <sm-notification-message header="Отчет формируется" type="info">
        <p class="report-notifications__text">
          Это займет какое-то время. После формирования начнется скачивание
          файла.
        </p>
        <div class="report-notifications__spinner-wrapper">
          <sm-icon
            name="document-download"
            outline
            class="report-notifications__icon"
          />
          <sm-spinner class="report-notifications__spinner" />
        </div>
      </sm-notification-message>
    </div>
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('reports');
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import smPermissionErrorMessage from '@/components/common/smPermissionErrorMessage.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmReportFilter from '@/components/common/forms/SmReportFilter.vue';
import SmNotificationMessage from '@/components/common/notification/SmNotificationMessage.vue';
import SmSpinner from '@/components/common/SmSpinner.vue';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SmReport',

  components: {
    SmPageLoader,
    smPermissionErrorMessage,
    SmBreadcrumbs,
    SmReportFilter,
    SmNotificationMessage,
    SmSpinner,
    SmIcon,
  },

  props: {
    reportName: {
      type: String,
      require: true,
    },

    breadcrumbs: {
      type: Array,
    },
  },

  data() {
    return {
      isLoadingPage: false,
      hasPermission: true,
      initialForm: null,
      form: null,
      reportLink: [
        {
          text: 'Экспортировать в CSV',
          type: 'csv',
        },
        {
          text: 'Экспортировать в  Excel',
          type: 'xlsx',
        },
      ],
      isLoadingReport: false,
      showNotificationMessage: false,
      report: '',
      dataForReportCreate: {
        reportName: this.reportName,
        parameters: [],
        format: 'html',
        reportViwerUid: null,
        supportedDeepLinks: ['OpenRequest', 'OpenKspRequestById'],
      },
      dataForReportExport: {
        reportName: this.reportName,
        format: null,
        reportViwerUid: null,
      },
      isLoadingGenerateAndExportReport: false,
      isLoadingExportReport: false,
      showExportReportModal: false,
      showCreateAndDownloadReportModal: false,
    };
  },

  computed: {
    ...mapState({
      reportInfo: (state) => state.reportInfo,
      // report: (state) => state.report,
      dependentList: (state) => state.dependentList,
      loadingSatusReport: (state) => state.loadingSatusReport,
    }),
  },

  created() {
    this.isLoadingPage = true;

    this.getReportInfo({ reportName: this.reportName })
      .then(() => {
        this.setInitialForm();
        this.form = this.lodash.cloneDeep(this.initialForm);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.hasPermission = false;
        }
      })
      .finally(() => {
        this.isLoadingPage = false;
      });

    let tabId = this.generateTabId();
    this.dataForReportExport.reportViwerUid = tabId;
    this.dataForReportCreate.reportViwerUid = tabId;
  },

  // destroyed() {
  //   this.resetStore();
  // },

  methods: {
    ...mapActions([
      'getReportInfo',
      'generateReport',
      'updateDependentList',
      'downloadReport',
      'resetStore',
      'exportReport',
    ]),

    setInitialForm() {
      const { parameters, parameterValueList } = this.reportInfo.data;
      this.initialForm = parameters.map((parameter) => {
        if (!parameter.listName) return parameter;

        const list = parameterValueList.find(
          (list) => list.name === parameter.listName
        );
        const processedList = list.values.map((value) => ({
          ...value,
        }));

        return {
          ...parameter,
          list: processedList,
          value: processedList[0].value,
        };
      });
    },

    setDependentList(dependentList) {
      const index = this.form.findIndex(
        (value) => value.listName === dependentList.name
      );

      if (index !== -1) {
        this.form[index].list = this.lodash.cloneDeep(dependentList.values);
        this.form[index].value = this.form[index].list[0].value;
      }
    },

    onSelect(field) {
      let parameterName = '';
      this.form.forEach((item) => {
        if (item.dependsOn === field.name) {
          parameterName = item.name;
        }
      });
      if (!parameterName) return;

      this.updateDependentList({
        reportName: this.reportName,
        parameterName,
        parameters: this.form,
      }).then((result) => {
        this.setDependentList(result);
      });
    },

    onCreate() {
      this.isLoadingReport = true;
      let parameters = this.form.map((parameter) => ({
        name: parameter.name,
        value: parameter.value,
      }));
      this.dataForReportCreate.parameters = parameters;
      this.generateReport(this.dataForReportCreate).then((result) => {
        this.report = result;
        this.isLoadingReport = false;
      });
    },

    onDownload(type) {
      this.showNotificationMessage = true;
      const parameters = this.form.map((parameter) => ({
        name: parameter.name,
        value: parameter.value,
      }));
      this.dataForReportCreate.parameters = parameters;
      this.downloadReport({
        reportName: this.reportName,
        parameters,
        format: type,
      }).finally(() => {
        setTimeout(() => {
          this.showNotificationMessage = false;
        }, 1500);
      });
    },

    onGenerateAndExport(type) {
      this.showNotificationMessage = true;
      this.isLoadingGenerateAndExportReport = true;
      const parameters = this.form.map((parameter) => ({
        name: parameter.name,
        value: parameter.value,
      }));
      this.dataForReportCreate.parameters = parameters;
      this.downloadReport({
        ...this.dataForReportCreate,
        parameters,
        format: type,
      }).finally(() => {
        this.isLoadingGenerateAndExportReport = false;
        this.showNotificationMessage = false;
        if (this.showExportReportModal) {
          this.showExportReportModal = false;
        }
        if (this.showCreateAndDownloadReportModal) {
          this.showCreateAndDownloadReportModal = false;
        }
      });
    },

    onExportExisting(type) {
      this.isLoadingExportReport = true;
      this.exportReport({
        ...this.dataForReportExport,
        format: type,
      }).finally(() => {
        this.isLoadingExportReport = false;
        this.showExportReportModal = false;
      });
    },

    onOpenCreateAndDownloadReportModal() {
      this.showCreateAndDownloadReportModal = true;
    },

    onCloseCreateAndDownloadReportModal() {
      this.showCreateAndDownloadReportModal = false;
    },

    onOpenExportReportModal() {
      this.showExportReportModal = true;
    },

    onCloseExportReportModal() {
      this.showExportReportModal = false;
    },

    generateTabId() {
      const uniqueId = Math.random().toString(16);

      sessionStorage.getItem('tabID') &&
      sessionStorage.getItem('closedLastTab') !== '2'
        ? sessionStorage.getItem('tabID')
        : sessionStorage.setItem('tabID', uniqueId);

      sessionStorage.setItem('closedLastTab', '2');

      ['unload', 'beforeunload'].forEach((event) =>
        window.addEventListener(event, function () {
          sessionStorage.setItem('closedLastTab', '1');
        })
      );
      return sessionStorage.getItem('tabID');
    },

    resolveHref(router, actionArgument) {
      const { href } = router.resolve({
        name: 'SupportRequestInfo',
        params: { id: actionArgument },
        query: { hmode: 1 },
      });
      return href;
    },

    onClickHyperlink(event) {
      const { action, actionargument: actionArgument } = event.target.dataset || {};

      if (!action) return;

      if (action === 'OpenKspRequestById' && !!actionArgument) {
        const href = this.resolveHref(this.$router, actionArgument);
        window.open(href, '_blank');
      }

    }
  },
};
</script>

<style lang="scss">
.report {
  position: relative;
}

.report__table {
  max-width: 100%;
  max-height: 600px;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    margin: 10px;
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;

    background-color: var(--gray);
    border-radius: 6px;
  }
}

.report__table-wrapper {
  padding: 20px;

  border: 1px solid var(--gray);
  border-radius: 10px;
}

.report__link-group {
  display: flex;
  justify-content: flex-end;
}

.report__link {
  font-weight: 600;
  color: var(--blue);
}

.report__link:first-of-type {
  margin-right: 10px;
}

.report-notifications__text {
  margin-bottom: 10px;
}

.report-notifications__spinner-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-notifications__icon {
  position: absolute;
  top: 26px;

  width: 28px;
  height: 28px;

  color: var(--blue);
}
</style>
